import React, { useEffect } from "react";

function Bookings(props) {
  useEffect(() => {
    document.title = `${props.pageTitle} - The Kinneuchar Inn`;

    const bookingWidget = document.querySelector(".widget-container");
    const bookingWidgetContainer = document.getElementById("booking-widget");

    if (bookingWidget) {
      bookingWidgetContainer.appendChild(bookingWidget);
      bookingWidget.style.display = "block";
    }

    if (props.pageImage) {
      document.body.style.backgroundImage = `url(${props.pageImage.fields.file.url})`;
      document.body.classList.add("has-image");
    }

    return () => {
      if (bookingWidget) {
        bookingWidget.style.display = "none";
      }
      document.body.appendChild(bookingWidget);
      document.body.classList.remove("has-image");
      document.body.style.backgroundImage = "none";
    };
  });

  return (
    <div className="Bookings">
      <h1>{props.pageTitle}</h1>
      <div dangerouslySetInnerHTML={{ __html: props.pageContent }}></div>
      <div id="booking-widget"></div>
    </div>
  );
}

export default Bookings;
