import { BLOCKS } from '@contentful/rich-text-types';

const richTextOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: { fields }
      }
    }) => `<img src="${fields.file.url}" alt="${fields.description}"/>`,
    [BLOCKS.PARAGRAPH]: (node, next) =>
      `<p>${next(node.content).replace(/\n/g, '<br />')}</p>`
  }
};

export default richTextOptions;
