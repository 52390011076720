import React, { useState, useEffect } from 'react';

function CookieBanner(props) {
  const [showBanner, hideBanner] = useState(true);

  useEffect(() => {
    if (window.localStorage.getItem('COOKIES_AGREED')) {
      hideBanner(false);
    }
  }, []);

  return (
    <>
      {showBanner ? (
        <div className="cookie-banner">
          <div className="container">
            <p>
              This website uses third-party cookies to manage your booking
              experience. For more information please see their{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://sales.resdiary.com/cookies-policy/"
              >
                privacy policy
              </a>
              .
            </p>
            <p>
              Unless you change your cookie settings we assume you're ok to
              continue.
            </p>
            <p>
              <label>
                <input
                  type="checkbox"
                  onClick={() => {
                    hideBanner(false);
                    window.localStorage.setItem('COOKIES_AGREED', true);
                  }}
                />
                I agree
              </label>
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CookieBanner;
