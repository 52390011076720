import React, { useEffect } from 'react';

function Page(props) {
  useEffect(() => {
    document.title = `${props.pageTitle} - The Kinneuchar Inn`;

    if (props.pageImage) {
      document.body.style.backgroundImage = `url(${props.pageImage.fields.file.url})`;
      document.body.classList.add('has-image');
    }

    return () => {
      document.body.classList.remove('has-image');
      document.body.style.backgroundImage = 'none';
    };
  });

  return (
    <div className="Page">
      <h1>{props.pageTitle}</h1>
      <div dangerouslySetInnerHTML={{ __html: props.pageContent }}></div>
    </div>
  );
}

export default Page;
