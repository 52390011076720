import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.png';

function Header() {
  return (
    <div className="site-header">
      <Link to="/">
        <img className="site-logo" src={logo} alt="The Kinneuchar Inn" />
      </Link>
    </div>
  );
}

export default Header;
