import React, { useEffect } from 'react';

import GalleryImage from '../GalleryImage';

function Gallery(props) {
  useEffect(() => {
    document.title = 'Gallery - The Kinneuchar Inn';
  });

  return (
    <div className="Gallery gallery-grid">
      {props.images.map(image => (
        <GalleryImage key={image.sys.id} image={image.fields.image} />
      ))}
    </div>
  );
}

export default Gallery;
