import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createClient } from "contentful";
import { EventEmitter } from "fbemitter";

// Config
import config from "../../config";

// Utils
import utils from "../../utils";

// Components
import AddressBlock from "../AddressBlock";
import Bookings from "../Bookings";
import CookieBanner from "../CookieBanner";
import Gallery from "../Gallery";
import Header from "../Header";
import Navigation from "../Navigation";
import NotFound from "../NotFound";
import Page from "../Page";
import SocialIcons from "../SocialIcons";

function App() {
  const [data, setData] = useState([]);
  const emitter = new EventEmitter();

  useEffect(() => {
    const client = createClient(config.contentful);
    client.getEntries().then((result) => setData(result.items));
  }, []);

  const pageData = data.filter(
    (item) => item.sys.contentType.sys.id === "page"
  );

  const addressData = data.find(
    (item) => item.sys.contentType.sys.id === "address"
  );

  const navigationData = data.find(
    (item) => item.sys.contentType.sys.id === "navigationList"
  );

  const galleryData = data.find(
    (item) => item.sys.contentType.sys.id === "galleryPage"
  );

  return (
    <Router>
      <Header />
      {pageData.length ? (
        <div className="container">
          <div className="grid-row">
            <div className="grid-col-4 navigation">
              <Navigation
                address={utils.renderHtml(addressData.fields.address)}
                emitter={emitter}
                links={navigationData.fields.links}
              />
            </div>
            <div className="grid-col-8 main-content">
              <Switch>
                {pageData.map((item) => (
                  <Route
                    key={item.fields.path + "0"}
                    exact
                    path={
                      item.fields.path === "about"
                        ? "/"
                        : "/" + item.fields.path
                    }
                    component={() =>
                      item.fields.path === "bookings" ? (
                        <Bookings
                          pageTitle={item.fields.pageTitle}
                          pageImage={item.fields.pageImage}
                          pageContent={utils.renderHtml(
                            item.fields.pageContent
                          )}
                        />
                      ) : (
                        <Page
                          pageTitle={item.fields.pageTitle}
                          pageImage={item.fields.pageImage}
                          pageContent={utils.renderHtml(
                            item.fields.pageContent
                          )}
                        />
                      )
                    }
                  />
                ))}
                <Route exact path="/gallery">
                  <Gallery images={galleryData.fields.images} />
                </Route>
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
          <div className="hide--large">
            <AddressBlock
              address={utils.renderHtml(addressData.fields.address)}
            />
            <SocialIcons />
          </div>
        </div>
      ) : null}
      <CookieBanner />
    </Router>
  );
}

export default App;
