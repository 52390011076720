import React, { useState } from "react";

function GalleryImage(props) {
  const [isOpen, toggleLightbox] = useState(false);

  return (
    <>
      <a
        href={props.image.fields.file.url}
        onClick={(e) => {
          e.preventDefault();
          toggleLightbox(true);
        }}
      >
        <img
          className="gallery-image"
          src={props.image.fields.file.url}
          alt=""
        />
      </a>
      <div className={isOpen ? "lightbox-image is-open" : "lightbox-image"}>
        <div className="modal-inner">
          <button
            className="link close-modal"
            onClick={(e) => {
              e.preventDefault();
              toggleLightbox(false);
            }}
          >
            &times; Close
          </button>
          <img src={props.image.fields.file.url} alt="" />
        </div>
      </div>
    </>
  );
}

export default GalleryImage;
