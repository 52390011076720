import React from 'react';

function AddressBlock(props) {
  return (
    <div
      className="address-block small-text"
      dangerouslySetInnerHTML={{ __html: props.address }}
    ></div>
  );
}

export default AddressBlock;
