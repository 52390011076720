import React from 'react';

import instagramIcon from '../../assets/images/icon_instagram.svg';
import facebookIcon from '../../assets/images/icon_facebook.svg';
import twitterIcon from '../../assets/images/icon_twitter.svg';

function SocialIcons() {
  return (
    <ul className="social-icons">
      <li>
        <a href="https://www.instagram.com/kinneucharinn/">
          <img
            src={instagramIcon}
            alt="The Kinneuchar Inn on Instagram"
            className="social-icon"
            title="Follow us on Instagram"
          />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/kinneucharInn/">
          <img
            src={facebookIcon}
            alt="The Kinneuchar Inn on Facebook"
            className="social-icon"
            title="Follow us on Facebook"
          />
        </a>
      </li>
      <li>
        <a href="/">
          <img
            src={twitterIcon}
            alt="The Kinneuchar Inn on twitter"
            className="social-icon"
            title="Follow us on twitter"
          />
        </a>
      </li>
    </ul>
  );
}

export default SocialIcons;
