import React, { useState } from 'react';

import NavigationList from '../NavigationList';
import AddressBlock from '../AddressBlock';

import SocialIcons from '../SocialIcons';

function Navigation(props) {
  const [hideNav, toggleNav] = useState(true);

  // eslint-disable-next-line
  const navEventListener = props.emitter.addListener('CLOSE_NAV', () => {
    toggleNav(true);
  });

  return (
    <>
      <button
        className={
          hideNav
            ? 'nav-toggle hide--large'
            : 'nav-toggle nav-toggle--open hide--large'
        }
        type="button"
        onClick={() => toggleNav(!hideNav)}
        title="Toggle navigation"
      >
        <div className="nav-button-bar"></div>
        <div className="nav-button-bar"></div>
        <div className="nav-button-bar"></div>
        <div className="nav-button-bar"></div>
      </button>
      <NavigationList
        hideNav={hideNav}
        emitter={props.emitter}
        links={props.links}
      />
      <div className="hide--small">
        <AddressBlock address={props.address} />
        <SocialIcons />
      </div>
    </>
  );
}

export default Navigation;
