import React from "react";
import { NavLink } from "react-router-dom";

function NavigationList(props) {
  const navOverlay = document.getElementById("nav-overlay");

  if (!props.hideNav) {
    navOverlay.classList.add("nav-open");
    navOverlay.classList.remove("hide-overlay");
  } else {
    navOverlay.classList.remove("nav-open");
    navOverlay.classList.add("hide-overlay");
  }

  return (
    <div className={props.hideNav ? "nav-hidden nav-wrapper" : "nav-wrapper"}>
      <ul className="nav-list">
        {props.links.map((link) =>
          link.fields ? (
            <li key={link.sys.id} className="nav-list__item">
              <NavLink
                className="nav-list__link"
                to={link.fields.path}
                onClick={(e) => {
                  e.stopPropagation();
                  props.emitter.emit("CLOSE_NAV");
                }}
              >
                {link.fields.label}
              </NavLink>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
}

export default NavigationList;
