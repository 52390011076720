import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { createRoot } from "react-dom/client";

import App from "./components/App";

import "./assets/fonts/BemboStd-Bold.eot";
import "./assets/fonts/BemboStd-Bold.eot?#iefix";
import "./assets/fonts/BemboStd-Bold.woff2";
import "./assets/fonts/BemboStd-Bold.woff";
import "./assets/fonts/Bembo.eot";
import "./assets/fonts/Bembo.eot?#iefix";
import "./assets/fonts/Bembo.woff2";
import "./assets/fonts/Bembo.woff";

import "./assets/styles/index.scss";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
