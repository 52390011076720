import React, { useEffect } from 'react';

function NotFound() {
  useEffect(() => {
    document.title = 'Page not found - The Kinneuchar Inn';
  });

  return (
    <>
      <h1>Error 404: Page not found</h1>
      <p>The page you are looking for can't be found.</p>
    </>
  );
}

export default NotFound;
